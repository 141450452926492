// src/components/discuss/PostForm.js

import React, { useState, useContext } from 'react';
import UserContext from '../../context/UserContext';
import Sidebar from './Sidebar';
import '../../css/Forum.css';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
const CREATE_POST_API_URL = `${API_BASE_URL}/api/forum/posts`;

const PostForm = ({ toggleForm }) => {
    const { user } = useContext(UserContext);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    // const [image, setImage] = useState(null); 
    const [error, setError] = useState(null);

    // const handleImageChange = (e) => {
    //     // console.log("You uploaded a image!")
    //     setImage(e.target.files[0]); 
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user || !user.token) {
            setError("You must be logged in to create a post");
            return;
        }

        const postData = {
            title,
            content
        };

        console.log("Post data:", postData);

        try {
            const response = await fetch(CREATE_POST_API_URL, {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create post');
            }
            const newPost = await response.json();
            console.log('New post created:', newPost);
            // Clear form
            setTitle('');
            setContent('');
            // setImage(null);
            // Reload to show the new post
            window.location.reload();
        } catch (err) {
            console.error('Error creating post:', err);
            setError(err.message);
        };
    };

    return (
        <div className="post-form-container">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="title" className="form-label">Title</label>
                    <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={title}
                        placeholder="Title"
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="title" className="form-label">
                        Body
                    </label>
                    <textarea
                        className="form-control"
                        name="content"
                        value={content}
                        placeholder="Content"
                        onChange={(e) => setContent(e.target.value)}
                        rows="4"
                        required
                    />
                </div>
                {/* <div className="form-group">
                    <label htmlFor="image" className="form-label label-text">上传照片 Upload Image: <span className="form-text">(自选 optional)</span></label>
                    <input type="file" className="form-control" id="image" name="image" onChange={handleImageChange} key={image ? 'not-reset' : 'reset'} />
                </div> */}
                <div className="btn-post-group">
                    <button className="btn btn-light btn-post btn-create-cancel" onClick={toggleForm}>Cancel</button>
                    <button className="btn btn-light btn-post btn-create-submit">Submit Post</button>
                </div>
            </form>
            {error && <div className="error">{error}</div>}
        </div>
    )
}

export default PostForm; 