// src/context/TitleContext.js
import React, { createContext, useState } from 'react';

export const TitleContext = createContext();

export const TitleProvider = ({ children }) => {
    const [currentTitleDetails, setCurrentTitleDetails] = useState(null);

    return (
        <TitleContext.Provider value={{ currentTitleDetails, setCurrentTitleDetails }}>
            {children}
        </TitleContext.Provider>
    );
};