// src/pages/TitlesPage.js
import React, { useState, useEffect } from 'react';
import TitleFilter from './TitleFilter';
import TitleSort from './TitleSort';
import TitleGrid from './TitleGrid';
import '../../css/Page.css';

// type prop will be 'cdrama', 'cmovie', 'manhua', 'donghua', 'cvariety', etc.
const TitlesPage = ({ type, typeName }) => {

    const [titles, setTitles] = useState([]);
    const [filters, setFilters] = useState({ genre: 'all', dynasty: 'all', year: 'all' });
    const [sortBy, setSortBy] = useState('aired');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({
        genre: { cn: '全部', en: 'All' },
        dynasty: { cn: "全部", en: 'All' },
        year: 'all',
        sortBy: { cn: '播出', en: 'Aired' }
    });
    const [selectedSummary, setSelectedSummary] = useState('');

    useEffect(() => {
        // Call this function to update the summary based on the current state
        updateSelectedSummary();

        const fetchTitles = async () => {
            setLoading(true);
            try {
                // Define the query parameters
                let queryParams = new URLSearchParams({
                    ...filters,
                    sortBy,
                    // Add the type to query parameters 
                    type
                }).toString();

                // Adjust the query parameters to exclude 'all' filters
                queryParams = queryParams.split('&').filter(q => !q.includes('all')).join('&');

                // Construct the API URL with query parameters
                const TITLES_QUERY_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/watch/titles?${queryParams}` || `http://localhost:3000/api/watch/titles?${queryParams}`;
                // console.log(TITLES_QUERY_API_URL)

                const response = await fetch(TITLES_QUERY_API_URL);

                if (!response.ok) {
                    // Get the filtered values for the error message
                    const { genre, dynasty, year } = filters; 
                    let filterText = ''; 

                    if (year !== 'all') {
                        filterText = year; 
                    } else if (genre !== 'all') {
                        filterText = selectedOptions.genre.en; 
                    } else if (dynasty !== 'all') {
                        filterText = selectedOptions.dynasty.en; 
                    }

                    const errorMsg = filterText 
                        ? `抱歉 😅，目前还没有${filterText}的片名，我们会持续添加新的内容，请稍后再来看看！:)\nOops 😅, there is no titles for ${filterText} yet, but we are constantly adding new titles, please check back! :)`
                        : `抱歉 😅，目前还没有符合筛选条件的片名，我们会持续添加新的内容，请稍后再来看看！:)\nOops 😅, there is no titles for your filtered search yet, but we are constantly adding new titles, please check back! :)`;
                    
                    throw new Error(errorMsg);
                }

                const data = await response.json();
                setTitles(data);
                setError(null); // Reset error state on successful fetch
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTitles();
        // Include 'type' in the dependency array    
    }, [filters, sortBy, type]);

    const handleFilterChange = (filterCategory, value) => {
        // setFilters(prev => ({ ...prev, [filterCategory]: value }));
        const backendValue = value === 'All' ? 'all' : value;
        setFilters(prev => ({ ...prev, [filterCategory]: backendValue }));
    };

    const handleSelectionChange = (category, { cn, en }) => {
        setSelectedOptions(prev => ({ ...prev, [category]: { cn, en } }));
    };

    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
    };
    // console.log(sortBy);

    // const updateSelectedSummary = () => {
    //     // Construct the summary string based on selected filters and sorting
    //     const genreText = filters.genre === 'all' ? 'All' : filters.genre; 
    //     const dynastyText = filters.dynasty === 'all' ? 'All': filters.dynasty; 
    //     const yearText = filters.year === 'all' ? 'All' : filters.year; 
    //     const sortByText = sortBy.charAt(0).toUpperCase() + sortBy.slice(1); // Capitalize the first letter  
    //     const summary = `Showing results for Genre: ${genreText} | Dynasty: ${dynastyText} | Year: ${yearText} | Sort by: ${sortByText}`; 
    //     setSelectedSummary(summary);
    // }

    /// Selected summary with Chinese name too: 
    const updateSelectedSummary = () => {
        const { genre, dynasty } = selectedOptions;
        const yearText = filters.year === 'all' ? '全部 All' : filters.year;
        const sortByTextEn = sortBy.charAt(0).toUpperCase() + sortBy.slice(1);
        // const sortByTextCn = getChineseNameForSortOption(sortBy); 
        const summary = `展示结果 Showing results：种类 Type: ${typeName} | 类别 Genre: ${genre.cn} ${genre.en} | 朝代 Dynasty: ${dynasty.cn} ${dynasty.en} | 年份 Year: ${yearText}`;
        // const summary = `展示结果 Showing results: 年份 Year: ${yearText} |  排序 Sorted by: ${sortByTextCn} ${sortByTextEn}`;
        // const summary = `展示结果 Showing results: 种类 Type: ${typeName} | 年份 Year: ${yearText} |  排序 Sorted by: ${sortByTextEn}`;
        // 排序 Sorted by: ${sortByTextCn} ${sortByTextEn}
        setSelectedSummary(summary);
    }

    return (
        <div className="page-wrapper">
            {/* <div className="filter-section-text">
                <h6>按类别和朝代查找即将推出！请暂时先按年份过滤或排序查找 ✨</h6>
                <h6>Filter by Genres and Dynasties coming soon! Meanwhile, please filter by Years or Sort by ✨</h6>
            </div> */}
            <div className="filter-sort-container">
                <TitleFilter
                    onFilterChange={handleFilterChange}
                    onSelectionChange={handleSelectionChange}
                    filters={filters}
                />
                <TitleSort onSortChange={handleSortChange} currentSort={sortBy} />
            </div>
            <div className="selected-summary">{selectedSummary}</div>
            {loading && <div className="message-container"><p>Loading...</p></div>}
            {error && (
            <div className="message-container">
                <div className="error-message">
                    <p>{error.split('\n')[0]}</p>
                    <p>{error.split('\n')[1]}</p>
                </div>
                {/* <img 
                    src="/images/no-results.png" 
                    alt="No titles found" 
                    className="error-image"
                /> */}
            </div>
        )}
            {!loading && !error && <TitleGrid titles={titles} />}
        </div>
    )
}

export default TitlesPage; 