// src/components/discuss/Forum.js

import React, { useState } from 'react';
import Sidebar from './Sidebar';
import PostForm from './PostForm';
import PostsList from './PostsList';
import '../../css/Page.css';
import '../../css/Forum.css';

const Forum = () => {
    const [showPostForm, setShowPostForm] = useState(false);

    const togglePostForm = () => {
        setShowPostForm(prevShowPostForm => !prevShowPostForm);
    };

    return (
        <div className="page-wrapper">
            <h5>论坛 🎙️</h5>
            <h5>Forum 🎙️</h5>
            <div className="form-header">
            </div>
            <div className="forum-main">
                {/* <Sidebar /> */}
                <div className="forum-container">
                    <div className="tabs">
                        <button className="tab btn btn-light btn-forum btn-create-post" onClick={togglePostForm}>
                            {showPostForm ? "Cancel" : "✍🏻 Create Post"}
                        </button>
                        <button className="tab active btn-light btn-forum">🕓 最新 Recent</button>
                        <button className="tab btn-light btn-forum">🔥 最火 Popular</button>
                        <button className="tab btn-light btn-forum">🎨 类别 Categories</button>
                    </div>

                    {showPostForm && <PostForm toggleForm={togglePostForm} />}
                    <PostsList />
                </div>
                {/* <div>Coming soon...❤️</div> */}
            </div>

        </div>
    )
};

// const Forum = () => {
//     const [showPostForm, setShowPostForm] = useState(false); 

//     const togglePostForm = () => {
//         setShowPostForm(prevShowPostForm => !prevShowPostForm); 
//     }; 

//     return (
//         <div className="bg-gray-50 min-h-screen">
//             <div className="container mx-auto py-8 px-4">
//                 <div className="flex justify-between items-center mb-6">
//                     <h1 className="text-2xl font-bold text-gray-800">Forum 🎙️</h1>
//                     <button
//                         className={`px-4 py-2 rounded-full font-medium transition ${
//                             showPostForm ? 'bg-red-500 text-white' : 'bg-blue-500 text-white'
//                         }`}
//                         onClick={togglePostForm}
//                     >
//                         {showPostForm ? 'Cancel' : 'Create Post ✍🏻'}
//                     </button>
//                 </div>

//                 {showPostForm && (
//                     <div className="mb-8">
//                         <PostForm toggleForm={togglePostForm} />
//                     </div>
//                 )}

//                 <div className="flex gap-4">
//                     <div className="w-3/12 bg-white rounded-lg shadow p-4">
//                         <h2 className="text-lg font-semibold mb-4">Categories</h2>
//                         <ul className="space-y-2">
//                             <li className="hover:text-blue-500 cursor-pointer">General</li>
//                             <li className="hover:text-blue-500 cursor-pointer">Hanfu Tips</li>
//                             <li className="hover:text-blue-500 cursor-pointer">Event Discussions</li>
//                             <li className="hover:text-blue-500 cursor-pointer">Other Topics</li>
//                         </ul>
//                     </div>
//                     <div className="w-9/12">
//                         <PostsList />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

export default Forum; 