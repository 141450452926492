// src/components/Breadcrumbs.js

import React, { useContext } from 'react';
import { TitleContext } from '../context/TitleContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { BsHouseFill } from 'react-icons/bs';
import { MdChevronRight } from 'react-icons/md';
import '../css/Breadcrumbs.css';

const Breadcrumbs = () => {
    const { currentTitleDetails } = useContext(TitleContext); 
    const location = useLocation();
    const navigate = useNavigate();
    const pathnames = location.pathname.split('/').filter(x => x);

    const dropdownPaths = {
        'watch': [
            { path: '/watch/trendings', label: '热门 Trending 🔥' },
            { path: '/watch/new', label: '新加 Newly Added 🎉' },
            { path: '/watch/cdramas', label: '连续剧 C-Drama 📺' },
            { path: '/watch/cmovies', label: '电影 C-Movie 🎬' },
            { path: '/watch/donghuas', label: '动画 Donghua 😻' },
            { path: '/watch/manhuas', label: '漫画 Manhua ✍🏻' }
        ],

        'create': [
            { path: '/create', label: '生成图像 Generate AI Image! 🎨' },
            { path: '/create/gallery', label: '社区画廊 Community Gallery 🖼️' },
        ]
    }

    const handleSelect = (path) => {
        navigate(path);
    }

    const routeLabels = {
        'watch': { en: 'Watch', cn: '观看' },
        'trendings': { en: 'Trending', cn: '热门' },
        'new': { en: 'Newly Added', cn: '新加' },
        'cdramas': { en: 'C-Drama', cn: '连续剧' },
        'cmovies': { en: 'C-Movie', cn: '电影' },
        'manhuas': { en: 'Manhua', cn: '漫画' },
        'donghuas': { en: 'Donghua', cn: '动画' },
        'titles': { en: 'Titles', cn: '片名' },
        'create': { en: 'Create', cn: '创作' },
        'gallery': { en: 'Gallery', cn: '画廊' },
        'shop': { en: 'Shop', cn: '购物' },
        // 'discuss': { en: 'Discuss', cn: '交流' },
        'forum': { en: 'Forum', cn: '论坛' },
        'events': { en: 'Events', cn: '活动' },
        'qrcode': { en: 'QR Code', cn: '二维码' },
        'pricing': { en: 'Pricing', cn: '订阅' },
        'contact': { en: 'Contact', cn: '联系' },
        'signup': { en: 'Signup', cn: '注册' },
        'login': { en: 'Login', cn: '登陆' },
    };

    const getTitleLabel = (name) => {
        // Check if it's a number (title ID) and we have currentTitleDetails
        if (!isNaN(name) && currentTitleDetails && currentTitleDetails.title_id.toString() === name) {
            return `${currentTitleDetails.title_name_cn} ${currentTitleDetails.title_name_en} (${currentTitleDetails.title_year})`; 
        }

        if (routeLabels[name]) {
            return `${routeLabels[name].cn} ${routeLabels[name].en}`
        }
        return name;
    }

    // const hasPage = {
    //     'watch': false,
    //     'create': false,
    // }

    return (
        <nav aria-label="breadcrumb" className="px-5">
            <ol className="breadcrumb m-0">
                <li className="breadcrumb-item d-flex align-items-center text-secondary">
                    <Link to="/" className="text-decoration-none d-flex align-items-center text-secondary">
                        <BsHouseFill className="me-1" />
                        <span>首页 Home</span>
                    </Link>
                </li>
                {pathnames.map((name, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathnames.length - 1;
                    const label = getTitleLabel(name);
                    return (
                        <li key={name}
                            className={`breadcrumb-item d-flex align-items-center text-secondary ${isLast ? 'active' : ''}`}
                            style={{ '--bs-breadcrumb-divider': 'none' }}>
                            <MdChevronRight className="mx-1" />
                            {dropdownPaths[name] ? (
                                <NavDropdown
                                    title={label}
                                    id={`${name}-dropdown`}
                                    className="p-0 m-0 breadcrumb-dropdown"
                                >
                                    {dropdownPaths[name].map((item) => (
                                        <NavDropdown.Item
                                            key={item.path}
                                            onClick={() => handleSelect(item.path)}
                                        >
                                            {item.label}
                                        </NavDropdown.Item>
                                    ))}
                                </NavDropdown>
                            ) : (
                                <span>
                                    {label}
                                </span>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs; 