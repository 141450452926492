import React from 'react';
// import { RiMovieFill } from "react-icons/ri";
// import { FaHeart, FaShoppingCart } from "react-icons/fa";
import LandingCarousel from './LandingCarousel';
import FAQ from './learn/FAQ';
// import HowItWorks from './create/HowItWorks';
import PricingChart from './pay/PricingChart';
import PricingStripe from './pay/PricingStripe';
import Partners from './about/Partners';
import "../css/Landing.css";

const Landing = () => {

    const bannerLink = "/watch/new";
    const bannerMessage = "中国新年快乐!🧧🥳🎉 快看我们最新添加的325个片名！Happy Chinese New Year!🧧🥳🎉 Check out our 325 newly added titles!";

    return (
        <div className="page-container">
            <div className="page-banner">
                <a href={bannerLink} target="_blank" rel="noopener noreferrer" className="banner-text">{bannerMessage}</a>
            </div>
            <div className="section-container-0">
                <div className="section-landing">
                    <LandingCarousel />
                </div>
            </div>
            <div className="section-container section-landing-intro">
                <div className="section-landing-header">
                    <h1 className="section-header-gradient-1">欢迎来到汉服世界! ❤️</h1>
                    <h1 className="section-header-gradient-1">Welcome to Hanfu World! ❤️</h1>
                </div>
                <div className="section-landing-text">
                    <span className="landing-text-cn">欣赏汉服影片。生成AI汉服头像。购买汉服及配饰。交流汉服话题。体验汉服活动。</span>
                    <span className="landing-text-en">Watch Hanfu shows. Create Hanfu AI portrait. Shop Hanfu and accessories. Discuss Hanfu topics. Experience Hanfu events.</span>
                </div>

                <div className="section-landing-buttons">
                    <button className="btn-landing btn-landing-0">
                        注册 Signup
                    </button>
                    <button className="btn-landing btn-landing-0">
                        登陆 Login
                    </button>
                </div>
            </div>
            <div className="section-container section-container-1">
                <div className="section-text">
                    {/* <button className="btn btn-light btn-landing btn-landing-1"> */}
                    <button className="btn-landing btn-landing-1">
                        <a href="/watch/trendings" target="_blank" rel="noopener noreferrer" className="section-link">
                            {/* <h2><RiMovieFill /> 看</h2> */}
                            {/* <h2>▶️ 看</h2>
                            <h2>WATCH</h2> */}
                            <h2 className="section-btn-text">▶️ 看</h2>
                            <h2 className="section-btn-text">WATCH</h2>
                        </a>
                    </button>
                    <p>观看或浏览最热门的汉服连续剧、电影、动画、漫画！</p>
                    <p>Explore the hottest Hanfu Cdramas, Cmovies, Donghuas, and Manhuas!</p>
                    <p>进入精彩绝伦的汉服世界，穿越到全新的空间、时间和维度！</p>
                    <p>Enter the exciting world of Hanfu and chuanyue (be transported) to another space, time, and dimension!</p>
                </div>
                <div className="section-image section-image-1">
                    <a href="/watch/trendings" className="section-link section-1-link" target="_blank" rel="noopener noreferrer">
                        <div className="horizontal-image-container">
                            <img className="landing-image landing-1-image" src="/images/landing/watch_3.png" alt="landing-watch" />
                            <img className="landing-image landing-1-image" src="/images/landing/watch_1.png" alt="landing-watch" />
                            <img className="landing-image landing-1-image" src="/images/landing/watch_2.png" alt="landing-watch" />
                        </div>
                    </a>
                </div>
            </div>
            <div className="section-container section-container-2">
                <div className="section-image section-image-2">
                    <a href="/create" className="section-link section-2-link" target="_blank" rel="noopener noreferrer">
                        <div className="rotate-image-container">
                            <img className="landing-image landing-2-image rotated-image top-image" src="/images/landing/create_1.png" alt="landing-create" />
                            <img className="landing-image landing-2-image rotated-image bottom-image" src="/images/landing/create_2.png" alt="landing-create" />
                        </div>
                    </a>
                </div>
                <div className="section-text">
                    <button className="btn-landing btn-landing-2">
                        <a href="/create" className="section-link" target="_blank" rel="noopener noreferrer">
                            <h2 className="section-btn-text">❤️ 做</h2>
                            <h2 className="section-btn-text">CREATE</h2>
                        </a>
                    </button>
                    <p>用我们的人工智能工具生成你自己的汉服肖像！</p>
                    <p>Create your very own Hanfu portrait using our AI tool!</p>
                    <p>来看看你在中国古装剧中扮演的角色会是什么样子吧！</p>
                    <p>See what you look like as a character in a historical Chinese drama! </p>
                </div>
            </div>
            <div className="section-container section-container-3">
                <div className="section-text">
                    <button className="btn-landing btn-landing-3">
                        <a href="/shop" className="section-link" target="_blank" rel="noopener noreferrer">
                            <h2 className="section-btn-text">🛍️ 买</h2>
                            <h2 className="section-btn-text">SHOP</h2>
                        </a>
                    </button>
                    <p>购买受汉服连续剧启发的华丽汉服和配饰！</p>
                    <p>Shop gorgeous Hanfus and accessories inspired by Hanfu dramas!</p>
                    <p>按朝代、品牌、风格、场合和网红推荐(KOL)浏览汉服和配饰！</p>
                    <p>Browse Hanfus and accessories by dynasties, brands, styles, occasions, and influencers!</p>
                </div>
                <div className="section-image section-image-3">
                    <a href="/shop" className="section-link section-3-link" target="_blank" rel="noopener noreferrer" >
                        <div className="cluster-image-container">
                            <img className="landing-image landing-3-image cluster-image" src="/images/landing/shop_1.png" alt="landing-shop" />
                            <img className="landing-image landing-3-image cluster-image" src="/images/landing/shop_4.png" alt="landing-shop" />
                            <img className="landing-image landing-3-image cluster-image" src="/images/landing/shop_2.png" alt="landing-shop" />
                            <img className="landing-image landing-3-image cluster-image" src="/images/landing/shop_3.png" alt="landing-shop" />
                        </div>
                    </a>
                </div>
            </div>
            <div className="section-container section-container-4">
                <FAQ />
            </div>
            <div className="section-container section-container-6">
                <div className="section-container">
                    <h2 className="section-header">订阅</h2>
                    <h2 className="section-header">Pricing</h2>
                    <PricingChart />
                    {/* <PricingStripe /> */}
                </div>
            </div>
            {/* <div className="section-container section-container-7">
                <Partners />
            </div> */}
            <div className="section-container section-container-8">
            </div>
        </div>
    )
}

export default Landing;

