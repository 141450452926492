// src/components/discuss/PostsList.js

import React, { useEffect, useState } from 'react';
import Post from './Post';
import { Link } from 'react-router-dom';
import '../../css/Forum.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
const POSTS_API_URL = `${API_BASE_URL}/api/forum/posts`;
// const POST_COMMENTS_API_URL = `${API_BASE_URL}/api/forum/posts/:postId/comments`;


const PostsList = () => {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            const response = await fetch(POSTS_API_URL);
            if (!response.ok) throw new Error("Failed to fetch posts");
            const data = await response.json();
            setPosts(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div>加载帖子中，请稍候 Loading posts, hang tight 😘...</div>
    if (error) return <div>Error: {error} </div>;

    return (
        <div className="posts-container">
        
            {/* {posts.map(post => (
                <Post key={post.post_id} post={post} />
            ))} */}

            <table className="posts-table">
                <thead>
                    <tr className="posts-table-header">
                        <th>Topic</th>
                        <th>Users</th>
                        <th>Likes</th>
                        <th>Comments</th>
                        <th>Views</th>
                    </tr>
                </thead>
                <tbody>
                    {posts.map(post => (
                        <Post key={post.post_id} post={post} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PostsList; 