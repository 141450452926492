const events = [
    {
        event_id: "1",
        event_title_cn: "端午节",
        event_title_en: "Dragon Boat Festival",
        event_description_cn: "",
        event_description_en: "", 
        event_start_date: "2024-6-8", 
        event_end_date: "2024-6-8",
        event_day_cn: "星期六",
        event_day_en: "Saturday", 
        event_start_time: "11:00:00", 
        event_end_time: "14:00:00",
        event_duration: "",
        event_location_cn: "", 
        event_location_en: "At West Valley College Kirkorian Pavilion",
        event_address_cn: "",
        event_address_en: "14000 Fruitvale Ave, Saratoga, CA 95070",
        event_city_cn: "",
        event_city_en: "Saratoga", 
        event_state_cn: "",
        event_state_en: "CA", 
        event_image_url:
        "https://hanfuworld-event-images.s3.us-east-2.amazonaws.com/duan_wu_jie_2024_6_8.png",
        event_host_cn: "",
        event_host_en: "Asian American for Better Community (AABC)",
        event_cohost_cn: "",
        event_cohost_en: "SYF Foundation, JT Family Foundation, SVBand, California Line Dance Association of America, Benefit Tomorrow Foundation",
        event_sponsors_cn: "",
        event_sponsors_en: "Growing Tree Learning Center, Little Tree Montessori International School, Coco Tan, Real Estate Broker, TamKang University Alumni (TUAANC), Northern California Hanfu Association, TACCNC",
        event_participant_cn: "北加州汉服社",
        event_participant_en: "Northern California Hanfu Association",
        event_dress_cn: "", 
        event_dress_en: "",
        event_price_usd: "", 
        event_parking: "",
        event_details_cn: "",
        event_details_en: "", 
        event_agenda_cn: "",
        event_agenda_en: "", 
        event_programs_cn: "", 
        event_programs_en: "",  
        event_affiliate_link: "", 
        event_link: 
        "",
        event_hosting_platform: "",
        event_status: "Upcoming",
        is_free: "true",
        event_type: "",
    }, 

    {
        event_id: "2",
        event_title_cn: "华体会",
        event_title_en: "Opening Ceremony Parade",
        event_description_cn: "",
        event_description_en: "", 
        event_start_date: "2024-8-3",
        event_end_date: "2024-8-3",
        event_day_cn: "星期六",
        event_day_en: "Saturday", 
        event_start_time: "09:00:00", 
        event_end_time: "10:30:00",
        event_duration: "",
        event_location_cn: "红山高中田径场",
        event_location_en: "Sequoia High School Track & Field Stadium",
        event_address_cn: "",
        event_address_en: "1201 Brewster Ave., Redwood City, CA 94062",
        event_city_cn: "",
        event_city_en: "Redwood City",
        event_state_cn: "",
        event_state_en: "CA", 
        event_image_url:
        "https://hanfuworld-event-images.s3.us-east-2.amazonaws.com/hua_ti_hui_2024_8_3.png",
        event_host_cn: "",
        event_host_en: "Asian American for Better Community (AABC)",
        event_cohost_cn: "",
        event_cohost_en: "SYF Foundation, JT Family Foundation, SVBand, California Line Dance Association of America, Benefit Tomorrow Foundation",
        event_sponsors_cn: "",
        event_sponsors_en: "Growing Tree Learning Center, Little Tree Montessori International School, Coco Tan, Real Estate Broker, TamKang University Alumni (TUAANC), Northern California Hanfu Association, TACCNC",
        event_participant_cn: "北加州汉服社",
        event_participant_en: "Northern California Hanfu Association",
        event_dress_cn: "传服、仙服、洋汉折衷、汉元素、马面裙搭白衬衫、汉服兽装都可以，展现汉服的各个不同侧面！去年我们的汉服美女抛绣球让很多人印象深刻，今年我们主打大唐风华！步障和鱼灯都准备好了，你们准备好了吗?",
        event_dress_en: "",
        event_price_usd: "", 
        event_details_cn: "",
        event_details_en: "", 
        event_agenda_cn: "",
        event_agenda_en: "", 
        event_programs_cn: "",
        event_programs_en: "", 
        event_affiliate_link: "",
        event_link: 
        "https://www.temu.com/wide-sleeve-hanfu-dress-ancient-chinese-traditional-costume-the-jin-dynasty-womens-clothing-g-601099513605106.html",
        event_hosting_platform: "",
        event_status: "Past",
        is_free: "true",
        event_type: "",
    }, 

]

export default events; 